// console.log = function(){}
export default {
    app: {
        name: "Viblica",
        url:"https://viblica.com",
        version: '0.0.1',
        contact:{
            email:"contact@viblica.com",
            phone:""
        },
        api: {
            server:"https://api.viblica.com",
            endpoint:"https://api.viblica.com/v1",
            baseUrl: "https://api.viblica.com/v1/client/",
            baseUrlIntegration: "https://api.viblica.com/v1/integration/",
            socket : "https://api.viblica.com/",
            logger : "https://api.viblica.com/",
            streamingServer : {
                host:"m.vblca.com",
                srt: "srt://m.vblca.com:9999",
                webcamTranscoder: "wss://m.vblca.com/webcamproxy/", //server that turn webcam data to rtmp
                http: "https://m.vblca.com",
                https: "https://m.vblca.com:3334",
                websocket: "wss://m.vblca.com:3333",
                rtmp: "rtmp://m.vblca.com/",
                apps:{
                    live:'live'
                }
            },
        }


    },
    flutterwave :{
        production:true,
        keys:{
            public :"FLWPUBK-fc5af6ca89f5c4e516a194a6dbaa635d-X" 
        }
    },
    paystack: {
        production: true,
        keys: {
            public: "pk_live_7e598933e79741cbebb17883acacb5eda353365d"
        }
    },
    googleServices:{
        analytics : "UA-169835564-1",
    },
    facebook:{
        appID: "704368907069771",
        endpoints:{
            publish_stream_detail : "https://graph.facebook.com/v3.3/me/live_videos"
        }
    },
    google:{
        appID: "412931478392-u7sod07geal7pe1evm2dhttlb9695ju8.apps.googleusercontent.com",
    }

}
