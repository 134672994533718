import React, { useState, useContext, useEffect, createRef } from 'react';
import { Form, Button, Input, Avatar, Divider, Modal, Spin, Icon, notification, Dropdown, Menu, message } from 'antd';
import GlobalStateContext from '../../../../context'
import Webservice from '../../../../classes/WebService';

import { _notification, ucFirst } from '../../../../utils';

import DestinationWebservice from '../../../../classes/DestinationWebservice';

import defaultIcon from "../../../../assets/images/rec.png"
import AddDestination from './AddDestination';
import StreamWebService from '../../../../classes/StreamWebService';
import plusIcon from '../../../../assets/images/plus.png'
import { Link } from '@material-ui/core';
import ConfirmDestinationDelete from '../../../../components/DestinationCard';

// type ConnnectedDestinationsProps = {
//   stream: Object
// }
// type ConnnectedDestinationItemProps = {
//   id: String,
//   channelImage: String,
//   platformImage: String,
//   active: Boolean,
//   onClick: Function,
//   isLoading: Boolean,
//   token: String,
//   platform: String,
//   playbackUrl:String,
//   platformResourceId:String
// }



// type ConnectedDestMenuProps = {
//   onClick: Function,
//   platform: String,
//   platformResourceId: String,
//   isActive: Boolean,
//   playbackUrl:String,
//   token:String
// }

const ConnectedDestMenu = (props) => {

  let [safeUrl, setSafeUrl] = useState("");
 

  useEffect(() => {

    let _safeUrl, id;
    if (props.playbackUrl) {
      if (props.platform == "twitch") {
        id = props.label
      } else {
        id = props.platformResourceId
      }

      _safeUrl = props.playbackUrl
        .replace("{video_id}", id)
        .replace("{channel_id}", id)

      console.log("safeurl: ", props.playbackUrl, _safeUrl)
      setSafeUrl(_safeUrl);

    }

  }, [props.isActive])


  return <>
    <div className='rounded-md bg-white'>

      <div className='bg-blue-600 py-1 px-3  text-white'>{props.label}</div>
      <Menu>

        <ul className='flex flex-col'>
          <li className='cursor-pointer hover:bg-gray-100 hover:outline  border-b-2 px-3 py-1 ' onClick={props.onClick} >
            <div>{props.isActive ? 'Disable' : 'Enable'}</div>
          </li>
          {(props.isActive && !!props?.platform && props?.platform != 'custom') && <li className='cursor-pointer hover:bg-gray-100 hover:outline px-3 py-1  ' >
            <div>

              <Link target='_blank' href={safeUrl}> View on {ucFirst(props?.platform)} </Link>
            </div>
          </li>}

          <li className='cursor-pointer hover:bg-red-100 hover:outline  border-b-2 px-3 py-1 ' onClick={props.onDelete} >
            <div>Delete</div>
          </li>
        </ul>



        {/* <img width={20} src={props?.platformImage} /> */}

        {/* </Menu.Item> */}
      </Menu>
    </div>
   
  </>
}

export function ConnectDestinationItem(props) {
  const active = (props.active ? ' active' : '')

  const item = (<div key={props.id} className={"added-channel"}


    onClick={() => props.onClick && props.onClick(props.id)} >

    {props.isLoading ? <Icon type="loading" style={{ fontSize: 50 }} spin /> : <>
      <div className='flex' style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
      }}>
        <Avatar className={"channel " + (active)}
          size={50}
          key={props.id}
          src={props.channelImage || defaultIcon}
          alt={props.id} />

        {props.platformImage && <Avatar
          className={'platform ' + active}
          style={{ "position": "relative", "background": "white", "margin": "-20px auto 0px auto" }}
          size={25}
          src={props.platformImage}
          alt={props.id} />}
      </div>

    </>
    }
  </div>)


  return props.id ?
    <Dropdown overlay={<ConnectedDestMenu label={props.label} token={props.token}
      platformImage={props.platformImage}
      platform={props.platform} isActive={props.active}
      playbackUrl={props.playbackUrl}
      platformResourceId={props.platformResourceId}
      onDelete={props.onDelete}
      onClick={() => props.onClick && props.onClick(props.id)} />}>
      {item}

    </Dropdown> :
    item

}


const ConnectDestinations = function (props) {
  const [state] = useContext(GlobalStateContext);
  const { features_accessible, user, io } = state;
  const { getFieldDecorator, setFieldsValue } = props.form;
  const [destinations, setDestinations] = useState([]);
  const [token, setToken] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [activeDestinations, setActiveDestinations] = useState(props.stream.destinations || []);
  const [showDestinationForm, setShowDestinationForm] = useState(false);
  // const [allow, setAllow] = useState(false);
  const formRef = createRef();
  const [systemDests, setSystemDests] = useState({});
  let [confirmDelete,setConfirmDelete] = useState(false)



  const systemDestsToDict = () => {
    const dict = {}
    for (let dest of state.stream_destinations) {
      dict[dest.id] = dest;
    }
    setSystemDests(dict);
  }


  const fetchDestinations = async () => {
    try {
      const webservice = new DestinationWebservice();
      webservice.fetchDestinations()
        .then(res => res.data.entries)
        .then(dest => setDestinations(dest || []));
    } catch (error) {
      _notification.error("Failed to fetch destinations");
    }
  }

  const cleanUp = () => {
    io.removeAllListeners(`platformtoken_${props.provider}_${props.key}`)
    setToken("");
  }

  useEffect(() => {
    systemDestsToDict();
    console.log("this")
    fetchDestinations();
    if (props.stream.destinations) {
      setActiveDestinations(props.stream.destinations.filter(el => el != null))
    }

    io.on(`${props.stream.token}_done_publish`, dests => { setActiveDestinations([dests]) })
    io.on(`${props.stream.token}_terminated`, fetchDestinations)
    return cleanUp;
  }, []);



  const onClick = async (destId) => {
    // e.preventDefault();
    console.log(activeDestinations)
    const isUnlimited = features_accessible?.max_destinations == -1
    const alreadyActive = activeDestinations.findIndex(dest => dest?.id == destId) != -1
    const exhusted = activeDestinations.length >= features_accessible?.max_destinations;
    console.log(isUnlimited, alreadyActive, exhusted)
    if (!isUnlimited && !alreadyActive && exhusted) {
      _notification.info(`You can only activate ${features_accessible.max_destinations} destinations per stream`)
      return
    }
    setLoading(destId);
    let service = new StreamWebService();


    await service.toggleDestination(props.stream.id, destId)

      .then(resp => {
        if (resp.status === 200) {

          setActiveDestinations(resp.data.destinations || []);

        } else {
          console.log("[x] respt", resp)
          if (`${resp.status}`.indexOf(4) == 0) { //4XX errorS
            Modal.info({

              content: resp.message,
              okText: resp.data.action ? "Reconnect" : "Okay",
              onOk() {
                resp.data?.action && window.open(resp.data?.action);

                io.on(`platformtoken_${resp.data?.provider}_${props.stream?.id}`, payload => {
                  setActiveDestinations([...activeDestinations, payload])
                  Modal.success({ content: "Destinated reconnnected successfully." })
                });

              }


            })
          } else {

            _notification.error(resp.message);
          }
        }
      })
      .catch(err => {
        _notification.error(`Operation failed. ${err.message}`);
      });
    setLoading(false);

  }

  
  const deleteDestination = () => {

    const wbs = new DestinationWebservice();
    console.log(confirmDelete)
    wbs.deleteDestination(confirmDelete?.id)
        .then(_ => {
          fetchDestinations().then(_=>{

            message.success(`Destination deleted`);
            setConfirmDelete(false);
          })
        })
        .catch(_ => {
            message.success(`Failed to delete destinaton`);
        })

}


  const sst = { maxWidth: "300px" }

  activeDestinations.filter(el => el != null)

  return (
    <>

      <div className='bg-blue-500 text-white px-2  rounded-b ' style={{
        position: "absolute"
      }}>
        Destinations
      </div>
      <div className="added-channels mt-4 flex justify-center" >

        {

          destinations.filter(des => !!des).map((dest) => {

            const pltf = systemDests[dest['platform']];
            const platformImage = pltf ? pltf['logo'] : "";
            const activeIndex = activeDestinations.findIndex(el => el?.id == dest.id)
            const activeConnectionObject = activeDestinations[activeIndex]

            return (
              <ConnectDestinationItem
                key={dest.id}
                label={dest.label}
                isLoading={isLoading == dest.id}
                active={activeIndex > -1}
                onClick={onClick}
                onDelete={_=>setConfirmDelete({...pltf,platformImage,...dest})}
                id={dest.id}
                platform={pltf?.provider}
                channelImage={dest.image_url}
                token={activeConnectionObject?.token}
                platformImage={platformImage}
                platformResourceId={activeConnectionObject?.resource_id}
                playbackUrl={pltf?.playback_url} />
            )
          })

        }


        <div className='added-channel'>

          <AddDestination callback={fetchDestinations} content={
            <ConnectDestinationItem channelImage={plusIcon} platformImage={""} />
          } />
        </div>



        {!!confirmDelete && <ConfirmDestinationDelete 
        platform={confirmDelete?.provider} 
        channel={confirmDelete?.label} 
        onConfirm={deleteDestination} 
        OnCancel={_=>setConfirmDelete(false)} platformImage={confirmDelete?.platformImage} 
        channelImage={confirmDelete?.image_url} />
        }

      </div>
    </>
  )
}

export default Form.create()(ConnectDestinations);