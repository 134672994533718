import React, { useState, useEffect, useContext } from 'react'
import { withRouter, useParams, Redirect, useHistory } from 'react-router-dom'
import StreamWebService from '../../../classes/StreamWebService';
import EmptyStream from './components/EmptyStream';
import Loading from '../../../components/Loading';
import GlobalStateContext from '../../../context';
import { STATUS_CLASS, STATUS_TEXTS, STREAM_FILE_STATUS_READY, STREAM_SOURCE_STUDIO, STREAM_SOURCE_URL, STREAM_STATUS_ACTIVE, STREAM_STATUS_DONE, STREAM_STATUS_INACTIVE } from '../../../constants';
import { _notification } from '../../../utils';


import ConnectDestinations from './components/ConnectDestinations';
import { Button, Icon, Tag } from 'antd';

import { MdPersonalVideo } from "react-icons/md";

import StreamFeaturesAccessible from './components/StreamFeaturesAccessible';
import Player from '../../../components/Player';
import { Link } from 'react-router-dom';
import { VideoParts } from '../../../components/ViewPublicStream';
import Greetings from '../../../components/Greetings';
import { StatCard } from '../../../components/DasboardStats';
import WaitingForStream from './components/WaitingForStream';
import Comments from '../../../components/comment/Comments';
import { AiOutlineControl ,AiOutlineRedo} from 'react-icons/ai';
import moment from 'moment';
import ShareStreamComponent from './components/ShareStreamComponent';
import { Helmet } from 'react-helmet';


// type Props = {
//     item: Event
// }

export const EventInfo = (props) => {
    const { item, metrics,reuse,processing } = props;

    return (
        <>
            <div className='grid grid-cols-2 gap-4 m-2'>

                {<StreamHeaderInfo item={item}/>}
                <div>
                    <div className="mt-4 sm:mt-1 flex gap-2">
                       
                    <ShareStreamComponent className={"md:block"} item={item}/>

                    {reuse && <Link to={`/${item.id}?r=1`} rel="noopener noreferrer" className='bg-blue-400  hover:bg-blue-300  px-3 py-2   rounded text-white  text-medium'>
                        <AiOutlineRedo size={15} className='inline' /> Reuse
                    </Link >}
                    {(item.status !== STREAM_STATUS_DONE && !reuse) && <Link to={`/${item.id}`}  rel="noopener noreferrer" className='bg-blue-400  hover:bg-blue-300  px-3 py-2   rounded text-white  text-medium'>
                        <AiOutlineControl size={15} className='inline' /> Control Studio
                    </Link >}
                    </div>
                </div>
            </div>



            <div className='row m-t-10 mx-0'>

                <div className='col-md-8 col-sm-12 p-0'>
                    { item.is_streaming || [STREAM_FILE_STATUS_READY].includes(item.file_status) || item.source == STREAM_SOURCE_URL ?
                        <>
                            <Player
                                showControls={true}
                                className="card-img-top br-10"
                                url={item.video}
                                isLive={item.status == STREAM_STATUS_ACTIVE}
                                // handleError={handleError}
                                height={"200px"}
                                roundEdges
                                isMuted={false}

                            />
                            {item.parts.length > 0 && <div className="mx-2 my-4">

                                <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">

                                    <div className='value'>
                                        <VideoParts parts={item.parts} />
                                    </div>
                                </dd>
                            </div>}

                        </>
                        :

                        <WaitingForStream
                            className="card-img-top aspect-w-16 aspect-h-9 bg-blue-100 br-10"
                            backdrop={item?.image}
                            style={{ border: 'none' }}
                            width={"none"}
                            height={"none"}
                            text={processing ? <><Icon type="sync" spin/> File Processing </>: "No stream or video playback available"}
                            description={processing ? "Please wait as the video file complets processing " : "Make sure you have started a stream "}
                        />

                    }

                    <div className='my-2 br-5 py-2 px-3 bg-gray-100 italic'>
                        <p> {item.description}</p>
                    </div>

                </div>
                <div className='col-md-4 col-sm-12 flex flex-row flex-wrap'>
                    <StatCard label="views" icon="eye" value={item.views} />
                    <StatCard label="interactions" icon="like" value={metrics?.interactions?.map(itm => itm.count).reduce((a, b) => a + b, 0)} />
                    <StatCard label="txs" icon="dollar" value={metrics?.tx?.map(itm => itm.count).reduce((a, b) => a + b, 0)} />
                    <StatCard label="followers" icon="usergroup-add" value={metrics?.followers?.map(itm => itm.count).reduce((a, b) => a + b, 0)} />
                </div>

            </div>

            <div className=''>
                <div className='my-4 mx-2'>
                    <h2 className='text-lg'>Comments</h2>
                    <Comments streamId={item.id} />
                </div>
            </div>




            {/* <div className='bg-white pt-3 border'>
            <div className="mt-6 border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Source</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{item.source}</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Status</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"> {props.item.is_upload && props.item.status == 0 ? "processing" : STATUS_TEXTS[props.item.status]}</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Visibility </dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">  {props.item.is_public ? "restricted" : "public"}</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Recording Active?</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">  {item.is_recording ? "Yes" : "No"}</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Streaming Active?</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0"> {item.is_streaming ? "Yes" : "No"}</dd>
                    </div>
                    <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Views Count</dt>
                        <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">  {item?.videos?.views || "-"}</dd>
                    </div>
                {item.parts && <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                        <dt className="text-sm font-medium leading-6 text-gray-900">Recordings</dt>
                        <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                            {item.parts.length > 0 &&
                                <div className='value'>
                                    <VideoParts parts={item.parts} />
                                </div>}
                        </dd>
                    </div>}
                </dl>
            </div>

             { (!props.item.is_upload && props.item.status == STREAM_STATUS_INACTIVE) &&
                <Link to={`/${props.item.id}`} >
                    <Button className='mt-5 text-center' type="primary" size="large" block >
                        <span className='mr-2'>
                            <MdPersonalVideo
                                style={{ verticalAlign: "middle" }}
                                size={20}
                            />
                        </span>Enter Studio </Button>
                </Link>
            }
        </div> */}

        </>
    )
}

export const EventActivityLog = (props) => {
    return (
        <div className='event-info'>
            <table>
                {
                    props.item.status_log.map(log => <tr>{log.status}</tr>)
                }
                <tr></tr>
            </table>
        </div>
    )
}
export default withRouter(function ViewStreamPage(props) {

    const [state, dispatch] = useContext(GlobalStateContext);
    const [isLoading, setIsLoading] = useState(true);
    const [stream, setStream] = useState(null);
    const [streamInsight, setStreamInsight] = useState({});
    const params = useParams()
    const id = params.id == 'instant' ? state.user.primary_stream : params.id;
    const [VideoSourceComponent, setVideoSourceComponent] = useState();
    const history = useHistory();
    const [isLive, setIsLive] = useState(false);
    const { reuse_stream } = state.features_accessible;
    const [processing, setProcessing] = useState(false);
    let [processIntervalCheck,setProgressInterval] = useState(null);
    const fetchItem = async (silent=false) => {

        if (id) {
            !silent&& setIsLoading(true);
            const service = new StreamWebService();
            const result = await (state.user ? service.getStream(id) : service.getStreamUnAuth(id));

            if (result.status === 200) {
                const item = result.data;
                setStream(item);
                setProcessing(item.status==0 && item.is_upload)
            }
            !silent&&setIsLoading(false);
        }
    }
    const getStreamInsight = async () => {

        if (id) {
            setIsLoading(true);
            const service = new StreamWebService();
            const result = await service.getInsight(id);

            if (result.status === 200) {
                setStreamInsight(result.data);
            }
            setIsLoading(false);
        }
    }



    useEffect(() => {
        console.log("Sdasdasd", id);
        fetchItem();
        getStreamInsight();


    }, []);
    useEffect(() => {

        if(processing){
            const _processIntervalCheck = setInterval(() => {
                fetchItem(true)
            }, 1000);
            setProgressInterval(_processIntervalCheck)
        }else{
            clearInterval(processIntervalCheck);
        }
        fetchItem();
        


    }, [processing]);


    // return  stream && <WebMediaStream  item={stream} />
    return (

        <><Helmet>
        <style type="text/css">
            {`video{
                border-radius:10px
            }`}
        </style>
    </Helmet>



            {
                <Loading loading={isLoading} >
                    {/* <StreamChat stream={stream}/> */}
                    {
                        !!stream ?
                            !!VideoSourceComponent ?

                                <div className="">
                                    <div className='row'>
                                        <div className='col-md-9'>
                                            <ConnectDestinations stream={stream} />
                                        </div>
                                    </div>
                                    {/* <div className='row'>

                                        <div className='col-sm-0 col-md-12 text-center'>

                                            <div className={' h3 text-white ' + (isLive ? ' live bg-danger' : ' ')} style={{
                                                margin: "auto 0px",
                                                height: "100%",
                                                padding: "2px 15px",
                                                backgroundColor: "#6a6a6a"
                                            }}>
                                                {stream.title}
                                            </div>

                                        </div>
                                    </div> */}

                                    {/* <VideoSourceComponent item={stream}
                                        // sourceType={activeInternalSourceType.key}
                                        onLiveStateChange={setIsLive}
                                        streamToken={stream.token} /> */}
                                    <StreamFeaturesAccessible />
                                </div>
                                :
                                <>



                                    {params.id &&
                                        <div className=' md:m-5 md:p-t-20'>

                                            <div className='event-info-container'>

                                                {/* <Title level={4} className=" text-left "> Event Info </Title > */}
                                                <div className='m-t-30'>
    
                                                    <EventInfo processing={processing} item={stream} metrics={streamInsight}  reuse={reuse_stream}/>
                                                </div>

                                            </div>
                                        </div>
                                    }
                                </>
                            :
                            <EmptyStream message="Sorry something went wrong" />}
                </Loading>
            }

        </>
    )
})

export function StreamHeaderInfo({item}) {
    return <div className='flex flex-col'>
        <h2 className="text-base font-semibold leading-7 text-gray-900">{item.title}</h2>
        <div className="mt-1 max-w-2xl text-sm text-gray-500">{ moment(item.createdAt).format("Do MMM YYYY, h:mm:ss a")}</div>
        <div className='flex'>
            <span className="mt-1 max-w-2xl text-sm text-gray-500"> <Tag><Icon type="api" /> {item.source}</Tag></span>
            <span className="mt-1 max-w-2xl text-sm text-gray-500"> <Tag> <Icon type="file" /> {item.is_upload && item.status == 0 ? "processing" : STATUS_TEXTS[item.status]}</Tag></span>
            <span className="mt-1 max-w-2xl text-sm text-gray-500"> <Tag> <Icon type="eye" /> {item.is_public ? "public" : "restricted"}</Tag></span>

        </div>

    </div>;
}
