
import React, { useState, useEffect, useContext } from 'react'
import { Tag, message, Icon, Badge } from 'antd'
import env from '../environments';
import GlobalStateContext from '../context';
import * as _ from 'lodash'
import StreamWebService from '../classes/StreamWebService';
import PaymentWebService from '../classes/PaymentWebService';
import { Pay } from './Pay';
import WaitingForStream from '../pages/protected/streams/components/WaitingForStream';
import { STREAM_STATUS_DONE } from '../constants';
import ReactPlayer from 'react-player';
import Loading from './Loading';
import { useParams } from 'react-router';

export default function EmbededVideo(props) {

    const { streamingServer } = env.app.api;
    const { live } = streamingServer.apps


    const [state, dispatch] = useContext(GlobalStateContext)
    const { io, user } = state;
    const [isLoading, setIsLoading] = useState(false);
    const [item, setItem] = useState(false);
    const [errorMsg, setErrorMsg] = useState(false);
    const [shouldMakePayment, setShouldMakePayment] = useState(false);


    const {id} = useParams()
 
    const [previewLink, setPreviewLink] = useState("")

    const fetchItem = async ()=>{
        if(id){
            setIsLoading(true);
            const service = new StreamWebService();
            const result = await service.getOpenPublicStreams({id});
            if(result.status ===  200){
                console.log(result.data);
                setItem(result.data);
            
            }
            setIsLoading(false);
        }
    }

    const handleDonePublish = (streamToken) => {
        // message.info("Stream ended.")
    }
    const handleTermination = (reason) => {
        message.error(`Stream Terminated. ${reason}`)
    }
    const handleError = (err) => {
        setErrorMsg("Connection error. Check stream source if you entered the right destination url")
    }

    const recordView = () => {

        const webservice = new StreamWebService();
        if (item.user) {
            if (user.id === item.user.id) {
                webservice.logView(id);
            }
        } else {
            webservice.logView(id);
        }

    }

    const confirmPayment = () => {
        const service = new PaymentWebService();
        service.confirm({
            entity: id,
            // amount : item.ppv_value
        }).then(res => setShouldMakePayment(!res.data))
    }


    const cleanUp = () => {
        io.removeAllListeners(`${item.token}_done_publish`);
        io.removeAllListeners(`${item.token}_terminated`);
        io.removeAllListeners(`view_count_${item.id}`);
    }


    useEffect(() => {
        fetchItem();
        io.on(`${item.token}_done_publish`, handleDonePublish)
        io.on(`${item.token}_terminated`, handleTermination);
        io.on(`view_count_${item.id}`, handleTermination);
        item.ppv_value > 0.00 && confirmPayment(); //confirm payment if the stream isn't free
        return cleanUp;

    }, []);

    useEffect(() => {
        const lnk = item.status === STREAM_STATUS_DONE ?
        `${streamingServer.http}/${live}/${item.token}/index.mp4` :
        `${streamingServer.websocket}/${live}/${item.token}.flv`
        setPreviewLink(lnk)

    }, [item]);

    const styles = {
        bodyContent: {
            display: "flex",
            justifyContent: "space-between"
        }
    }

    return (


        <Loading loading={isLoading}>
        
                 
                        {shouldMakePayment ?

                            <WaitingForStream text={
                                <>
                                    <p> Payment required to proceed </p>
                                    <Pay amount={item.ppv_value} entity={item} redirect_url={window.location} showButton={true}

                                    />
                                </>
                            } />

                            :

                            // http://localhost:8000/live/8a9de2e2-e824-4b90-ac78-b2a543e484b1/index.mp4
                            <ReactPlayer
                                
                                style = {{background:"#000"}}
                                playsinline
                                playing={true}
                                controls
                                onStart={recordView}
                                width="100%"
                                height="100vh"
                                url={previewLink}
                       
                            />
                        }
           
      

        </Loading>
    )
}
